import { useEffect } from 'react';
import Link from 'next/link';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { Button, Loader } from '@ui/core';
import { GST_VALUE } from '@components/buyers/checkout/summary/constants';
import { Form, useForm } from '@components/ReactHookForm/Form';
import { api } from '@api';
import { useAnimateCartIcon } from '@hooks/useAnimateCartIcon';
import useAppAnalytics from '@hooks/useAppAnalytics';
import { useAuth } from '@hooks/useAuth';
import { getCurrencySymbol } from '@utils/currency';
import { convertDateToFormattedString } from '@utils/date';
import { convertNumberToPriceFormat, getGrossMargin, getWHS } from '@utils/functions';
import { convertStringToNumber, NumberStringSchema } from '@utils/numberInput';
import { EVENTS } from '@constants/events';
import { ROUTES } from '@constants/route';
import QuickBuyTable from './QuickBuyTable';
import QuickBuyTableRow from './QuickBuyTableRow';

export const QuickBuyFormSchema = z.object({
  primaryAttributeName: z.string().nullable(),
  quantities: z.array(
    z.object({
      attribute: z.string().nullable(),
      colourPill: z
        .object({
          name: z.string(),
          hex: z.string().nullable(),
        })
        .nullable(),
      deliveryDate: z.date().nullable(),
      variants: z.array(
        z
          .object({
            sku: z.string(),
            size: z.string(),
            price: z.number().nonnegative(),
            whs: z.number().nonnegative(),
            quantity: NumberStringSchema, // schema for a string where a number is required
            stockAvailable: z.number().nonnegative().nullable(),
          })
          .refine(({ quantity, stockAvailable }) => {
            return convertStringToNumber(quantity) <= (stockAvailable ?? 0);
            // return quantity <= stockAvailable;
          })
      ),
    })
  ),
});

export type QuickBuyFormSchemaType = z.infer<typeof QuickBuyFormSchema>;

type CartPayload = Parameters<typeof api.buyers.cart.addVariants.mutation>['0']['cart'];

interface QuickBuyModalProps {
  closeModal: () => void;
  product?: z.infer<typeof api.sellers.inventory.getProduct.schema>;
  productId?: string;
}

const QuickBuyModal = ({
  closeModal,
  product: productFromProps,
  productId,
}: QuickBuyModalProps) => {
  const { startCartIconAnimation } = useAnimateCartIcon();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { trackEvent } = useAppAnalytics();
  const retailerId = user?.activeRetailer?.id as number;

  const shouldFetchProduct = productId && !productFromProps;

  const productQuery = useQuery({
    queryKey: [api.sellers.inventory.getProduct.queryKey, { itemId: productId, retailerId }],
    queryFn: () =>
      api.sellers.inventory.getProduct.query({ itemId: productId as string, retailerId }),
    staleTime: 1000 * 60 * 5,
    enabled: shouldFetchProduct,
  });

  const product = productFromProps ?? productQuery.data;

  const form = useForm({
    schema: QuickBuyFormSchema,
    defaultValues: {
      quantities: [],
      primaryAttributeName: '',
    },
  });

  const { reset } = form;

  const addVariants = useMutation({
    mutationFn: ({
      retailerId,
      cart,
      selectedPrimaryVariants: _,
    }: {
      retailerId: number;
      cart: CartPayload;
      selectedPrimaryVariants: { attribute: string }[];
    }) => api.buyers.cart.addVariants.mutation({ retailerId, cart }),
    onSuccess: (_, params) => {
      queryClient.invalidateQueries([api.buyers.cart.getCart.queryKey]);
      startCartIconAnimation();
      toast.success(
        <div className="flex gap-2 whitespace-nowrap">
          <span>Successfully added product to cart</span>
          <Link href={ROUTES.BUYERS.CART_CHECKOUT} className="mr-2 text-andisor-blue underline">
            Go to Cart
          </Link>
        </div>,
        {
          className: '!max-w-[380px]',
          duration: 3000,
        }
      );
      // send product cart add event for every selected primaryVariant to posthog
      // for products with no primary variants, send one event with selectedVariant as null
      if (product) {
        params.selectedPrimaryVariants.forEach(variant => {
          trackEvent(EVENTS.BUYERS.PRODUCT_CART_ADD, {
            itemId: product.id,
            productName: product.name,
            brandId: product.brand.id,
            brandName: product.brand.name,
            productType: product.productType,
            selectedVariant: variant.attribute,
          });
        });
      }
      closeModal();
    },
    onError: () => {
      toast.error('Unable to save product to cart');
    },
  });

  const getTotalQty = () => {
    return form.watch('quantities').reduce((total = 0, current) => {
      return (
        total +
        current.variants.reduce((t = 0, curr) => {
          return t + convertStringToNumber(curr.quantity);
        }, 0)
      );
    }, 0);
  };

  const getTotalPrice = () => {
    return form.watch('quantities').reduce((total = 0, current) => {
      return (
        total +
        current.variants.reduce((t = 0, curr) => {
          return t + convertStringToNumber(curr.quantity) * curr.whs;
        }, 0)
      );
    }, 0);
  };

  const calculateGrossMargin = () => {
    /*
     How to calculate margin for a product?
      As an example, imagine a product with 2 variants, green and black, that have 2 different prices and therfore 2 different WHS

      example product link used: http://localhost:3000/buyers/product/64588633c6b2af2041573f19

      green shoe:   RRP = 160,  WHS = 110.95
      black shoe:   RRP: = 162, WHS = 112.33

                              green shoe              black shoe
      total WHS =  (2 quantity * 110.95 WHS) + (1 quantity * 112.33 WHS) = 334.23

      1.1 is reffering to the GST_VALUE

      green shoe = 160 RRP / 1.1 = 145.4545
      black shoe = 162 RRP / 1.1 = 147.2727

      total RRP = (2 quantity * (160 / 1.1)) + (1 quantity * (162 / 1.1))
                            290.909           +      147.2727   =    438.1817

      gross margin = (total RRP - total WHS) / total RRP     * 100
                    =  (438.1817 - 334.23) / 438.1817 * 100  = 23.7234

      gross margin is 23.72 %
    */
    const discount = product?.discount;
    const quantities = form.watch('quantities');

    let totalWHS = 0;
    let totalRRP = 0;

    quantities.forEach(variant => {
      variant.variants.forEach(atomicVariant => {
        const quantity = convertStringToNumber(atomicVariant.quantity);
        const RRP = atomicVariant.price;
        totalRRP = totalRRP + quantity * (RRP / GST_VALUE);

        const WHS = product?.persona
          ? getWHS({
              discountType: 'persona',
              discount: discount ?? 0,
              RRP: RRP,
              additionalDiscount: product?.additionalDiscount ?? 0,
            })
          : getWHS({
              discountType: 'global',
              discount: discount ?? 0,
              RRP: RRP,
            });

        totalWHS = totalWHS + quantity * WHS;
      });
    });

    const grossMargin = getGrossMargin({
      RRP: totalRRP,
      WHS: totalWHS,
    });

    const margin = Number.isNaN(grossMargin) ? 0 : grossMargin;

    return Math.round(margin * 100) / 100;
  };

  const submitQuickBuyForm = (data: QuickBuyFormSchemaType) => {
    const allProducts: CartPayload = [];

    let customValidation = true;
    let hasShownDeliveryDateError = false;

    let totalQuantitySelected = 0;
    const selectedPrimaryVariants = [];
    for (const variant of data.quantities) {
      let totalQuantityOfVariant = 0;
      for (const atomicVariant of variant.variants) {
        const atomicVariantQty = convertStringToNumber(atomicVariant.quantity);
        totalQuantityOfVariant += atomicVariantQty;
        if (atomicVariantQty > 0 && variant.deliveryDate) {
          // even if deliveryDate is null, we wont add to cart
          // unless delivery date is selectd
          const deliveryDate = variant.deliveryDate;
          allProducts.push({
            sku: atomicVariant.sku,
            quantity: atomicVariantQty,
            requestedFor: convertDateToFormattedString(deliveryDate),
          });
        }
      }

      // when the total quantity for a variant is more than 0
      // and the delivery date is not selected, show error msg
      if (
        !hasShownDeliveryDateError &&
        totalQuantityOfVariant > 0 &&
        variant.deliveryDate === null
      ) {
        toast.error(`You must select a delivery date for ${variant.attribute}`);
        customValidation = false;
        hasShownDeliveryDateError = true;
      }

      if (totalQuantityOfVariant > 0 && variant.attribute) {
        selectedPrimaryVariants.push({ attribute: variant.attribute });
      }
      totalQuantitySelected += totalQuantityOfVariant;
    }

    if (!(totalQuantitySelected > 0)) {
      toast.error(`You must first add some units`);
      customValidation = false;
    }

    if (customValidation) {
      addVariants.mutate({
        retailerId,
        cart: allProducts,
        selectedPrimaryVariants,
      });
    }
  };

  useEffect(() => {
    if (product) {
      const getDefaultFormValues = () => {
        const quantities: QuickBuyFormSchemaType['quantities'] = [];

        product?.variants.map(variant => {
          // hide indent products
          if (variant?.published === true && !variant.indentOnly) {
            quantities.push({
              attribute: variant.value ?? null,
              colourPill: variant.colourPill ?? null,
              deliveryDate: null,
              variants: variant.variants.map(atomicVariant => ({
                price: atomicVariant.price ?? 0,
                whs: atomicVariant.whsMap['0'] ?? 0,
                quantity: '',
                size: atomicVariant.value ?? '',
                sku: atomicVariant.sku,
                stockAvailable: atomicVariant?.stock ?? 0,
              })),
            });
          }
        });

        return quantities;
      };

      reset({
        quantities: getDefaultFormValues(),
        primaryAttributeName: product?.primaryAttribute,
      });
    }
  }, [product, reset]);

  return (
    <>
      {shouldFetchProduct && (
        <>
          {productQuery.isFetching && (
            <div className="my-20 flex w-[500px] items-center justify-center">
              <Loader />
            </div>
          )}
        </>
      )}
      {product && (
        <>
          <div className="flex flex-col gap-1">
            Note: This is for at once orders with a delivery window less than 90 days.
          </div>
          <Form
            form={form}
            onSubmit={submitQuickBuyForm}
            disableForm={addVariants.isLoading}
            className="w-full min-w-0"
          >
            <QuickBuyTable
              availableSizes={form
                .watch(`quantities.${0}.variants`)
                ?.map(atomicVariant => atomicVariant.size)}
              data={form.getValues()}
              row={({ row }) => <QuickBuyTableRow row={row} form={form} product={product} />}
            />

            <div className="flex justify-center gap-16 text-xl font-semibold">
              <div className="flex flex-col items-center gap-2">
                <span>Total Qty</span>
                <span>{getTotalQty().toLocaleString()}</span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <span>Total Price</span>
                <span>
                  {getCurrencySymbol()}
                  {convertNumberToPriceFormat(getTotalPrice())}
                </span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <span>Margin</span>
                <span>{calculateGrossMargin().toFixed(2)}%</span>
              </div>
            </div>

            <div className="mt-5 flex justify-center">
              <Button type="submit" loading={addVariants.isLoading}>
                Add To Cart
              </Button>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default QuickBuyModal;
