import { IconInfoCircle } from '@tabler/icons-react';
import { ScrollArea, Tooltip } from '@ui/core';
import Table from '@components/Table/Table';
import { TH } from '@components/Table/TH';
import { TR } from '@components/Table/TR';

export const QuickBuyTable_TD = {
  colour: 'w-[200px]',
  deliveryDate: 'w-[150px]',
  price: 'w-[100px] justify-end',
  size: 'min-w-[100px] justify-end flex-1',
  totalQty: 'w-[100px] justify-end',
  totalPrice: 'w-[100px] justify-end',
};

interface QuickBuyTableProps<TData extends object> {
  data: { quantities?: TData[]; primaryAttributeName?: string | null | undefined };
  row: ({ row }: { row: TData }) => React.ReactNode;
  availableSizes: string[];
  isEditView?: boolean;
}
// this table is generic and just gives a skeleton of how the table should look
// the data passed in and the row it renders depends on your needs
const QuickBuyTable = <TData extends object>({
  data,
  row,
  availableSizes,
  isEditView = false,
}: QuickBuyTableProps<TData>) => {
  return (
    row &&
    availableSizes && (
      <ScrollArea type="auto" className="w-full overflow-visible">
        <Table
          tableClassName="rounded-md shadow-md my-4 w-full"
          tableHeadClassName="bg-andisor-blue text-white font-semibold"
          tableDataClassName="p-2"
          tableHeadDataClassName="p-2"
          tableBodyClassName="overflow-y-auto max-h-[400px]"
          data={data.quantities}
          row={row}
          head={
            <TR type="thead">
              {data.primaryAttributeName !== null && (
                <TH className={QuickBuyTable_TD.colour}>{data.primaryAttributeName}</TH>
              )}
              <TH className={QuickBuyTable_TD.deliveryDate}>
                Delivery Date
                {isEditView && (
                  <Tooltip label={`Can't be edited in this view`} position="top">
                    <span className="ml-2 flex items-center">
                      <IconInfoCircle className="text-andisor-gray" size={18} />
                    </span>
                  </Tooltip>
                )}
              </TH>
              {availableSizes.map((size, idx) => (
                <TH key={idx} className={QuickBuyTable_TD.size}>
                  <Tooltip label={size} position="top">
                    <span className="truncate px-1">{size}</span>
                  </Tooltip>
                </TH>
              ))}
              <TH className={QuickBuyTable_TD.totalQty}>Total Qty</TH>
              <TH className={QuickBuyTable_TD.totalPrice}>Total Price</TH>
            </TR>
          }
        />
      </ScrollArea>
    )
  );
};

export default QuickBuyTable;
