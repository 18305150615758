import { addDays, startOfToday } from 'date-fns';
import { Controller, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { DatePickerInput, NumberInput, Tooltip } from '@ui/core';
import { cx } from '@ui/utils';
import ColorPill from '@components/ColorPill/ColorPill';
import { getMaskedStock } from '@components/ProductDataGrid/utils';
import { useTableRowContext } from '@components/Table/RowWrapper';
import { TD } from '@components/Table/TD';
import { TR } from '@components/Table/TR';
import { api } from '@api';
import { getCurrencySymbol } from '@utils/currency';
import { convertNumberToPriceFormat } from '@utils/functions';
import { allowNumberStringOnly, convertStringToNumber } from '@utils/numberInput';
import { QuickBuyFormSchemaType } from './QuickBuyModal';
import { QuickBuyTable_TD } from './QuickBuyTable';

interface QuickBuyTableRowProps {
  row: QuickBuyFormSchemaType['quantities'][number];
  form: UseFormReturn<QuickBuyFormSchemaType>;
  product: z.infer<typeof api.sellers.inventory.getProduct.schema>;
}

const QuickBuyTableRow = ({ form, row, product }: QuickBuyTableRowProps) => {
  const tableRowContext = useTableRowContext();
  const variantIndex = tableRowContext.rowIndex;

  const currentRow = form.watch(`quantities.${variantIndex}`);
  const deliveryDateOfRow = currentRow.deliveryDate;

  const getTotalQuantityForAttribute = () => {
    return currentRow.variants.reduce((t = 0, current) => {
      return t + convertStringToNumber(current.quantity);
    }, 0);
  };

  const getTotalPrice = () => {
    return currentRow.variants.reduce((t = 0, current) => {
      return t + convertStringToNumber(current.quantity) * current.whs;
    }, 0);
  };

  return (
    <>
      <TR>
        {product.primaryAttribute !== null && (
          <TD className={QuickBuyTable_TD.colour}>
            <div className="flex items-center gap-2">
              {row.attribute ? (
                <>
                  {row.colourPill && <ColorPill color={row?.colourPill?.hex} />}
                  <Tooltip label={row.attribute} position="top">
                    <span className="w-[165px] truncate">{row.attribute}</span>
                  </Tooltip>
                </>
              ) : (
                '-'
              )}
            </div>
          </TD>
        )}

        <TD className={QuickBuyTable_TD.deliveryDate}>
          <DatePickerInput
            value={deliveryDateOfRow}
            valueFormat="DD-MMM-YYYY"
            className="w-full"
            placeholder="Click to select"
            minDate={startOfToday()}
            maxDate={addDays(startOfToday(), 90)}
            onChange={(date: Date | null) =>
              form.setValue(`quantities.${variantIndex}.deliveryDate`, date)
            }
            popoverProps={{
              zIndex: 10001,
              withinPortal: true,
            }}
            hideOutsideDates
          ></DatePickerInput>
        </TD>

        {row.variants.map((atomicVariant, index) => {
          const quantity = form.watch(`quantities.${variantIndex}.variants.${index}.quantity`);

          const stockAvailable =
            form.watch(`quantities.${variantIndex}.variants.${index}.stockAvailable`) ?? 0;
          return (
            <TD key={atomicVariant.sku} className={QuickBuyTable_TD.size}>
              <Controller
                name={`quantities.${variantIndex}.variants.${index}.quantity`}
                control={form.control}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    type="text"
                    value={field.value || quantity}
                    onChange={val => {
                      field.onChange(allowNumberStringOnly(`${val}`));
                    }}
                    min={0}
                    allowDecimal={false}
                    error={convertStringToNumber(quantity) > stockAvailable}
                    classNames={{
                      input: 'w-full text-right disabled:cursor-not-allowed disabled:opacity-60',
                    }}
                  />
                )}
              />
            </TD>
          );
        })}

        <TD className={cx(QuickBuyTable_TD.totalQty, 'py-4')}>{getTotalQuantityForAttribute()}</TD>
        <TD className={cx(QuickBuyTable_TD.totalPrice, 'py-4')}>
          {getCurrencySymbol()}
          {convertNumberToPriceFormat(getTotalPrice())}
        </TD>
      </TR>

      <TR>
        {product.primaryAttribute !== null && (
          <TD className={cx(QuickBuyTable_TD.colour, 'py-0.5')}></TD>
        )}
        <TD className={cx(QuickBuyTable_TD.deliveryDate, 'py-0.5')}>
          <span className="font-semibold text-andisor-blue">Price</span>
        </TD>

        {row.variants.map((atomicVariant, index) => {
          return (
            <TD
              key={atomicVariant.sku + '-price-item-' + index}
              className={cx(QuickBuyTable_TD.size, 'py-0.5')}
            >
              <span className="px-1.5">
                {getCurrencySymbol()}
                {convertNumberToPriceFormat(atomicVariant.whs)}
              </span>
            </TD>
          );
        })}

        <TD className={cx(QuickBuyTable_TD.totalQty, 'py-0.5')}>-</TD>
        <TD className={cx(QuickBuyTable_TD.totalPrice, 'py-0.5')}>-</TD>
      </TR>

      <TR>
        {product.primaryAttribute !== null && (
          <TD className={cx(QuickBuyTable_TD.colour, 'py-1')}></TD>
        )}
        <TD className={cx(QuickBuyTable_TD.deliveryDate, 'py-1')}>
          <span>Available Stock</span>
        </TD>

        {row.variants.map((atomicVariant, index) => {
          return (
            <TD
              key={atomicVariant.sku + '-stock-item-' + index}
              className={cx(QuickBuyTable_TD.size, 'py-1')}
            >
              <span className="px-1.5">{getMaskedStock(atomicVariant?.stockAvailable || 0)}</span>
            </TD>
          );
        })}

        <TD className={cx(QuickBuyTable_TD.totalQty, 'py-1')}>-</TD>
        <TD className={QuickBuyTable_TD.totalPrice}>-</TD>
      </TR>
    </>
  );
};

export default QuickBuyTableRow;
